$(window).on("load",function(){
});
$(function(){

	var $body = $('body');

	//SVGスプライト
	$.ajax({
		type: 'get',
		url: '/wp/wp-content/themes/ai-training/assets/svg/icons.svg'//ここにパスを入れる（環境に合わせて変更）
	}).done(function(data) {
		var svg = $(data).find('svg');
		$('body').prepend(svg);
	});

	//ページ内スクロール
	$.smooziee();

	//スクロールしたらクラスを付与
	$(window).scroll(function () {
		if ($(this).scrollTop() > 300) {
			$body.addClass("scroll");
		} else {
			$body.removeClass("scroll");
		}
	});

	//開閉用ボタンをクリックでクラスの切替え
	$('.header_sp_btn').on('click', function () {
		$body.toggleClass('open');
		// bodyにopenがあれば
		if($($body).hasClass('open')){
			$('.header_sp_btn_text').html('CLOSE');
		} else {
			$('.header_sp_btn_text').html('MENU');
		}
		return false;
	});

	//メニュー名以外の部分をクリックで閉じる
	$('.layer , #nav_about').on('click', function () {
		$body.removeClass('open');
		$('.header_sp_btn_text').html('MENU');
	});

	// スクロールアニメーション
	new WOW().init();

});