$(function () {

	//ポップアップ
	$('.popup-modal-single').magnificPopup({
		type: 'image', 
	});

	//フッターバナースライド(スマホのみ)
	var voice_swiper;
	$(window).on('load resize', function(){
		var w = $(window).width();
		if (w <= 768) {
			if (voice_swiper) {
				return;
			} else {
				voice_swiper = new Swiper('.voice_swiper', {
					loop: true,
					// speed: 1500,
					autoplay: {
						delay: 4000,
					},
					spaceBetween: 18,
					slidesPerView: 1.25,
					centeredSlides: true,
					autoHeight: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
				});
			}
		} else {
			if (voice_swiper) {
				voice_swiper.destroy();
				voice_swiper = undefined;
			}
		}
	});

});